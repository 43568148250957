html { font-family: 'Roboto', sans-serif;  }


.App {
  text-align: center;
}

.footer {
  margin-top: 2vh;
  margin-bottom: 1vh;
  font-size: 50%;
  color: white;
}

ul {
  text-align: left;
}

.logo {
  width: 50vw;
  max-width: 250px;
  pointer-events: none;
}

#drop {
  height: 50vh;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#drop:hover {
  cursor: pointer;
}

.dragged {
  /* border: 2px solid white !important; */
  border: 2px solid #3eb989 !important;
}

pre {
  margin: 0;
  display: inline-block;
}

table {
  display: block;
  font-size: 1.2rem;
}

td, th {
  min-width: 120px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

*:focus {
  outline: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0E2A47;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
